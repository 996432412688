import React, { Component } from 'react';

import ClientStripe from '../../assets/images/circle-stripe.png';

class ExceptionalClientCare extends Component {
  constructor() {
    super();
    this.state = {
      startCounter: false,
    };
  }

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ startCounter: true });
    }
  };

  render() {
    return (
      <section className='about-one '>
        <img src={ClientStripe} className='about-one__circle' alt='' />
        <div className='container text-center'>
          <div className='block-title text-center'>
            <h2 className='block-title__title'>
              EXCEPTIONAL CLIENT CARE
              <br />
            </h2>
          </div>
          <p className='about-one__text'>
            Our client’s success is the only relevant measures of our own
            success. We endeavor to create strong committed tailor-made
            solutions that will add significant value. Each case is handled with
            great care, consideration and attention to detail in the safe hands
            of our highly skilled lawyers.
            <br />
            <br />
            We expect our clients to expect the best legal service and advice.
            Our clients trust us, because we put great emphasis on quality and
            accuracy of advice, which underpins every aspect of our service.
            Even in circumstances when fast and efficient advice is required, we
            never compromise our level of quality.
          </p>
        </div>
      </section>
    );
  }
}

export default ExceptionalClientCare;
