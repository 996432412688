import React, { useEffect, useState } from 'react';

import AboutTheCompany from '../components/home/aboutCompany';
import ExceptionalClientCare from '../components/home/expectionalClientCare';
import Footer from '../components/common/footer';
import HomepageCarousel from '../components/home/homepageCarousel';
import LatestNewsAndArticles from '../components/home/latestNewsAndArticles';
import Layout from '../components/common/layout';
import NavigationMenu from '../components/common/navigationMenu';
import OurClientele from '../components/home/ourClientele';
import OurIntern from '../components/about/ourInterns';
import OurPeople from '../components/about/ourPeople';
import ServicesOffered from '../components/home/servicesOffered';
import Testimonials from '../components/home/testimonials';
import WhoWeAre from '../components/about/whoWeAre';

const DisclaimerPopup = ({ onClose, onAccept }) => {
  return (
    <div
      className='disclaimer-popup'
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        overflow: 'auto', // Make sure the outer container can scroll
      }}
    >
      <div
        className='disclaimer-content'
        style={{
          position: 'relative',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
          maxWidth: '80%',
          maxHeight: '90vh', // Ensure the content does not exceed viewport height
          overflowY: 'auto', // Make inner content scrollable
          marginTop: '40px',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '5px',
            right: '15px',
            backgroundColor: 'transparent',
            color: 'rgb(241, 97, 1)',
            cursor: 'pointer',
            fontSize: '24px',
            paddingRight: '10px',
          }}
          onClick={onClose}
        >
          ×
        </div>
        <div style={{ padding: '20px' }}>
          <p>
            The rules of the Bar Council of India prohibit law firms from
            advertising and soliciting work through communication in the public
            domain. This website is meant solely for the purpose of information
            and not for the purpose of advertising.
          </p>
          <p>
            Avadhani & Associates does not intend to solicit clients through
            this website. We do not take responsibility for decisions taken by
            the reader based solely on the information provided on the website.
            By clicking on ‘ACCEPT’, the visitor acknowledges that the
            information provided on the website <br />
            (a) does not amount to advertising or solicitation <br />
            (b) is meant only for their understanding about our activities and
            who we are.
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '15px',
          }}
        >
          <button
            onClick={onAccept}
            style={{
              backgroundColor: 'rgb(241, 97, 1)',
              color: 'white',
              border: 'none',
              padding: '10px 20px',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            ACCEPT
          </button>
        </div>
      </div>
    </div>
  );
};

const HomePageTwo = () => {
  const [showDisclaimer, setShowDisclaimer] = useState(true);

  useEffect(() => {
    const disclaimerAccepted = localStorage.getItem('disclaimerAccepted');
    if (disclaimerAccepted) {
      setShowDisclaimer(false);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('disclaimerAccepted', 'true');
    setShowDisclaimer(false);
  };

  const handleClose = () => {
    setShowDisclaimer(false);
  };

  return (
    <Layout pageTitle='Avadhani & Associates | Home'>
      {showDisclaimer && (
        <DisclaimerPopup onAccept={handleAccept} onClose={handleClose} />
      )}
      <NavigationMenu />
      <HomepageCarousel />
      <WhoWeAre />
      <ServicesOffered />
      <AboutTheCompany />
      <ExceptionalClientCare />
      <OurClientele />
      <Testimonials />
      <OurPeople />
      <OurIntern />
      <LatestNewsAndArticles />
      <Footer />
    </Layout>
  );
};

export default HomePageTwo;
