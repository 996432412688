import React, { Component } from 'react';

import ClientStripe from '../../assets/images/circle-stripe.png';

class AboutTheCompany extends Component {
  constructor() {
    super();
    this.state = {
      startCounter: false,
    };
  }

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ startCounter: true });
    }
  };

  render() {
    return (
      <section className='about-one '>
        <img src={ClientStripe} className='about-one__circle' alt='' />
        <div className='container text-center'>
          <div className='block-title text-center'>
            <h2 className='block-title__title'>
              ABOUT AVADHANI AND ASSOCIATES
              <br />
            </h2>
          </div>
          <p
            className='about-one__text'
            style={{
              paddingBottom: '30px',
            }}
          >
            At Avadhani and Associates, our approach to legal practice reflects
            the persistently developing world around us. Challenges and choices
            become increasingly complex and regulation more rigid. This calls
            for a unique approach to legal practice with prominence on
            tailor-made and cost-efficient solutions and advice. Our people are
            not just lawyers. We have hand-picked some of the finest expert
            lawyers, who all have extensive experience and invaluable strategic
            knowledge in each of their own field. This enables us to deliver
            personalized legal advice to an extensive range of clients from
            businesses of all sizes to individuals, across many different
            fields.
            <br />
          </p>
        </div>
      </section>
    );
  }
}

export default AboutTheCompany;
