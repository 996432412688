import React from 'react';
// Import images
import book1 from '../../assets/images/books/science of evidence second edition.jpg';
import book10 from '../../assets/images/books/Science of Evidence.jpg'; // Add image paths if available
import book11 from '../../assets/images/books/Hindu Women Gender Issues & Law.jpg'; // Add image paths if available
import book12 from '../../assets/images/books/Criminal Trial & Enquiries (Law, Practice & Procedure).jpg'; // Add image paths if available
import book13 from '../../assets/images/books/legitimate expectations a multi dimensional.jpg'; // Add image paths if available
import book14 from '../../assets/images/books/Law Of Deeds Instruments & Documents With Allied Matters.jpg'; // Add image paths if available
import book15 from '../../assets/images/books/Law of Intellectual Property Law .jpg'; // Add image paths if available
import book16 from '../../assets/images/books/Lesson On Law Of Hindu Family & Property _ Principle And Procedure [Edition 2020-2021].jpg'; // Add image paths if available
import book17 from '../../assets/images/books/Lessons on Common Law, Equity, and Constitutional Remedies.jpg'; // Add image paths if available
import book18 from '../../assets/images/books/Criminal Investigation (Law, Practice & Procedure).jpg'; // Add image paths if available
import book19 from '../../assets/images/books/లా లోచనం.jpg'; // Add image paths if available
import book2 from '../../assets/images/books/law of civil wrongs.jpg';
import book3 from '../../assets/images/books/Sentencing and Victim Compensation Principles and Practices.jpg';
import book4 from '../../assets/images/books/Interlocutory Applications.jpg'; // Add image paths if available
import book5 from '../../assets/images/books/Civil Adjudication.jpg'; // Add image paths if available
import book6 from '../../assets/images/books/Lessons on Law of Witnesses and Evidence.jpg'; // Add image paths if available
import book7 from '../../assets/images/books/law of pleadings in civil court book.jpg'; // Add image paths if available
import book8 from '../../assets/images/books/civil adjudication law.jpg'; // Add image paths if available
import book9 from '../../assets/images/books/Lessons on Burden of Proof.jpg'; // Add image paths if available

// Define the book data with updated image filenames
const books = [
  { title: 'Science of Evidence Second Edition', image: book1 },
  { title: 'Law of Civil Wrongs', image: book2 },
  {
    title: 'Sentencing and Victim Compensation Principles and Practices',
    image: book3,
  },
  { title: 'Interlocutory Applications', image: book4 },
  { title: 'Civil Adjudication', image: book5 },
  { title: 'Lessons on Law of Witnesses and Evidence', image: book6 },
  { title: 'Law of Pleadings in Civil Court', image: book7 },
  { title: 'Civil Adjudication Law', image: book8 },
  { title: 'Lessons on Burden of Proof', image: book9 },
  { title: 'Science of Evidence', image: book10 },
  { title: 'Hindu Women Gender Issues & Law', image: book11 },
  {
    title: 'Criminal Trial & Enquiries (Law, Practice & Procedure)',
    image: book12,
  },
  {
    title: 'Legitimate Expectations: A Multi-Dimensional Perspective',
    image: book13,
  },
  {
    title: 'Law Of Deeds, Instruments & Documents With Allied Matters',
    image: book14,
  },
  { title: 'Law of Intellectual Property Law', image: book15 },
  {
    title:
      'Lesson On Law Of Hindu Family & Property: Principle And Procedure [Edition 2020-2021]',
    image: book16,
  },
  {
    title: 'Lessons on Common Law, Equity, and Constitutional Remedies',
    image: book17,
  },
  {
    title: 'Criminal Investigation (Law, Practice & Procedure)',
    image: book18,
  },
  { title: 'లా లోచనం', image: book19 },
];

const LatestNewsAndArticles = () => {
  return (
    <section className='blog-one blog-one__home-two'>
      <div className='container'>
        <div className='block-title text-center'>
          <h2 className='block-title__title'>
            Our latest news <br />& articles
          </h2>
        </div>
        <div className='row'>
          {books.map((book, index) => (
            <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12' key={index}>
              <div className='blog-one__single'>
                <div className='blog-one__image'>
                  <img src={book.image} alt={book.title} />
                </div>
                <div className='blog-one__content text-center'>
                  <h2 className='blog-one__title'>{book.title}</h2>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default LatestNewsAndArticles;
