import React from 'react';
import intern1 from '../../assets/images/Interns/mahathi.jpg';
import intern10 from '../../assets/images/Interns/kirti.jpg';
import intern11 from '../../assets/images/Interns/amith.jpg';
import intern12 from '../../assets/images/Interns/shaik.jpg';
import intern13 from '../../assets/images/Interns/mayukh.jpg';
import intern14 from '../../assets/images/Interns/allam.jpg';
import intern15 from '../../assets/images/Interns/snigdha.jpg';
import intern16 from '../../assets/images/Interns/posanpally.jpg';
import intern17 from '../../assets/images/Interns/kuthpady.jpg';
import intern18 from '../../assets/images/Interns/gorantla.jpg';
import intern19 from '../../assets/images/Interns/roshan.jpg';
import intern2 from '../../assets/images/Interns/bolla.jpg';
import intern20 from '../../assets/images/Interns/vaishali.jpg';
import intern21 from '../../assets/images/Interns/chandana.jpg';
import intern22 from '../../assets/images/Interns/laasya.jpg';
import intern23 from '../../assets/images/Interns/parasa.jpg';
import intern24 from '../../assets/images/Interns/nissi.jpg';
import intern3 from '../../assets/images/Interns/krishna.jpg';
import intern4 from '../../assets/images/Interns/chakradhar.jpg';
import intern5 from '../../assets/images/Interns/maddepally.jpg';
import intern6 from '../../assets/images/Interns/sanhitha.jpg';
import intern7 from '../../assets/images/Interns/dharini.jpg';
import intern8 from '../../assets/images/Interns/nandini.jpg';
import intern9 from '../../assets/images/Interns/maranganty.jpg';

const TeamInterns = () => {
  return (
    <section className='team-one team-one__become-teacher' id='team-section'>
      <div className='container'>
        <div className='container text-center'>
          <div className='block-title text-center'>
            <h2 className='block-title__title'>
              OUR INTERNS
              <br />
            </h2>
          </div>
          <h1 className='blog-one__title' style={{ paddingBottom: '0px' }}>
            Internship programme at Avadhani and Associates:
          </h1>
          <p className='about-one__text'>
            The internship is aimed at providing a learning opportunity to the
            young students. It is the best way to put theoretical knowledge to
            practice. At Avadhani and Associates, we offer summer and winter
            internships for the talent from top law schools in India. It is a
            four to eight-week internship program where students gain practical
            knowledge on client interactions, taking case briefs, research
            works, court procedures, drafting and get mentored by subject matter
            experts. Upon successful completion, interns get an Internship
            completion certificate. Some of our successful interns are:
          </p>
        </div>
        <div className='row'>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-interns__image'>
                <img src={intern1} alt='Mahathi Ambadipudi' />
              </div>
              <div className='team-interns__content'>
                <h2 className='team-interns__name'>Mahathi Ambadipudi</h2>
                <p className='team-interns__designation'>Intern</p>
                <p className='team-interns__text'>
                  3rd year law student at ICFAI Law School, Hyderabad
                </p>
              </div>
            </div>
          </div>

          {/* Add the rest of the interns here in similar fashion */}

          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-interns__image'>
                <img src={intern14} alt='Ms. Allam Tony Nishitha Reddy' />
              </div>
              <div className='team-interns__content'>
                <h2 className='team-interns__name'>
                  Ms. Allam Tony Nishitha Reddy
                </h2>
                <p className='team-interns__designation'>Intern</p>
                <p className='team-interns__text'>
                  2nd year law student at CHRIST (Deemed to be University),
                  Pune, Lavasa Campus
                </p>
              </div>
            </div>
          </div>

          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-interns__image'>
                <img src={intern15} alt='Ms. Snigdha Chenepalli' />
              </div>
              <div className='team-interns__content'>
                <h2 className='team-interns__name'>Ms. Snigdha Chenepalli</h2>
                <p className='team-interns__designation'>Intern</p>
                <p className='team-interns__text'>
                  3rd year law student at Jindal Global Law School (JGLS), NCR
                  of Delhi
                </p>
              </div>
            </div>
          </div>

          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-interns__image'>
                <img src={intern16} alt='Posanpally Sathvik Reddy' />
              </div>
              <div className='team-interns__content'>
                <h2 className='team-interns__name'>Posanpally Sathvik Reddy</h2>
                <p className='team-interns__designation'>Intern</p>
                <p className='team-interns__text'>
                  2nd year law student at ICFAI Law School, Hyderabad
                </p>
              </div>
            </div>
          </div>

          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-interns__image'>
                <img src={intern17} alt='Kuthpady Padithaya Shubhada' />
              </div>
              <div className='team-interns__content'>
                <h2 className='team-interns__name'>
                  Kuthpady Padithaya Shubhada
                </h2>
                <p className='team-interns__designation'>Intern</p>
                <p className='team-interns__text'>
                  3rd year law student at CHRIST (Deemed to be University),
                  Pune, Lavasa Campus
                </p>
              </div>
            </div>
          </div>

          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-interns__image'>
                <img src={intern18} alt='Gorantla Bhargav Gandhi' />
              </div>
              <div className='team-interns__content'>
                <h2 className='team-interns__name'>Gorantla Bhargav Gandhi</h2>
                <p className='team-interns__designation'>Intern</p>
                <p className='team-interns__text'>
                  3rd year law student at CHRIST (Deemed to be University),
                  Pune, Lavasa Campus
                </p>
              </div>
            </div>
          </div>

          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-interns__image'>
                <img src={intern19} alt='Roshan Sallagundla Chowdary' />
              </div>
              <div className='team-interns__content'>
                <h2 className='team-interns__name'>
                  Roshan Sallagundla Chowdary
                </h2>
                <p className='team-interns__designation'>Intern</p>
                <p className='team-interns__text'>
                  3rd year law student at CHRIST (Deemed to be University),
                  Pune, Lavasa Campus
                </p>
              </div>
            </div>
          </div>

          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-interns__image'>
                <img src={intern20} alt='Ms. Vaishali Siddireddy' />
              </div>
              <div className='team-interns__content'>
                <h2 className='team-interns__name'>Ms. Vaishali Siddireddy</h2>
                <p className='team-interns__designation'>Intern</p>
                <p className='team-interns__text'>
                  4th year law student at ICFAI Law School, Hyderabad
                </p>
              </div>
            </div>
          </div>

          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-interns__image'>
                <img src={intern21} alt='Ms. Chandana Pagadala' />
              </div>
              <div className='team-interns__content'>
                <h2 className='team-interns__name'>Ms. Chandana Pagadala</h2>
                <p className='team-interns__designation'>Intern</p>
                <p className='team-interns__text'>
                  4th year law student at ICFAI Law School, Hyderabad
                </p>
              </div>
            </div>
          </div>

          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-interns__image'>
                <img src={intern22} alt='Ms. P. Laasya Swaraj' />
              </div>
              <div className='team-interns__content'>
                <h2 className='team-interns__name'>Ms. P. Laasya Swaraj</h2>
                <p className='team-interns__designation'>Intern</p>
                <p className='team-interns__text'>
                  4th year law student at ICFAI Law School, Hyderabad
                </p>
              </div>
            </div>
          </div>

          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-interns__image'>
                <img src={intern23} alt='Ms. Parasa Tanmayee' />
              </div>
              <div className='team-interns__content'>
                <h2 className='team-interns__name'>Ms. Parasa Tanmayee</h2>
                <p className='team-interns__designation'>Intern</p>
                <p className='team-interns__text'>
                  4th year law student at Damodaram Sanjivayya National Law
                  University, Visakhapatnam
                </p>
              </div>
            </div>
          </div>

          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-interns__image'>
                <img src={intern24} alt='Nissi Deborah' />
              </div>
              <div className='team-interns__content'>
                <h2 className='team-interns__name'>Nissi Deborah</h2>
                <p className='team-interns__designation'>Intern</p>
                <p className='team-interns__text'>
                  2nd year law student at Damodaram Sanjivayya National Law
                  University, Visakhapatnam
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamInterns;
