import 'swiper/css/swiper.css';

import React from 'react';
import Swiper from 'react-id-swiper';

const AreasOfWork = () => {
  const params = {
    slidesPerView: 5,
    loop: true,
    speed: 1000,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },

    breakpoints: {
      1024: {
        slidesPerView: 5,
      },
      768: {
        slidesPerView: 4,
      },
      640: {
        slidesPerView: 3,
      },
      320: {
        slidesPerView: 2,
      },
    },
  };

  const items = [
    { title: 'Financial Services', color: 'color-1' },
    { title: 'Music Industry', color: 'color-2' },
    { title: 'Television', color: 'color-3' },
    { title: 'Pharmaceutical', color: 'color-4' },
    { title: 'Real Estate', color: 'color-5' },
    { title: 'Film Industry', color: 'color-6' },
    { title: 'Educational Institutions', color: 'color-1' },
    { title: 'Societies', color: 'color-3' },
    { title: 'Infrastructure Investment', color: 'color-4' },
    { title: 'Aviation', color: 'color-1' },
    { title: 'Power Sector', color: 'color-2' },
  ];

  return (
    <section className='course-category-two'>
      <div className='container' style={{ paddingTop: 0, marginTop: 0 }}>
        <div
          className='inner-container'
          style={{ paddingTop: 0, marginTop: 0 }}
        >
          <div
            className='course-category-two__carousel'
            style={{ paddingTop: 0, marginTop: 0 }}
          >
            <Swiper {...params}>
              {items.map((item, index) => (
                <div className='item' key={index}>
                  <div className={`course-category-two__single ${item.color}`}>
                    <div className='course-category-two__icon'>
                      {/* Add your icon here */}
                    </div>
                    <div
                      className='course-category-two__title'
                      title={item.title}
                    >
                      {item.title}
                    </div>
                  </div>
                </div>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AreasOfWork;
