import 'swiper/css/swiper.css';

import BandlaGanesh from '../../assets/images/testimonials/bandla-ganesh.jpg';
import KrishnaReddy from '../../assets/images/testimonials/krishna-reddy.jpg';
import Prabhavathi from '../../assets/images/testimonials/prabhavathi.jpg';
import RajenderReddy from '../../assets/images/testimonials/rajender-reddy.jpg';
import React from 'react';
import SrinivasaRaju from '../../assets/images/testimonials/srinivasa-raju.jpg';
import Srinivasan from '../../assets/images/testimonials/srinivasan.jpg';
import Swiper from 'react-id-swiper';
import VenkateshwarRao from '../../assets/images/testimonials/venkateshwar-rao.jpg';
import Vishwanath from '../../assets/images/testimonials/vishwanath.jpg';
import quoteImage from '../../assets/images/qoute-1-1.png';
import unknown from '../../assets/images/testimonials/unknown.jpg';

const testimonials = [
  {
    name: 'Vishwanath Polepeddi',
    designation: 'Client',
    image: Vishwanath,
    text:
      'When you approach them they give a feeling of COMFORT They listen to you with Care and give you tons of CONFIDENCE “Avadhani Associates”, is complete ASSURANCE',
  },
  {
    name: 'DR. V. SRINIVASA RAJU',
    designation: 'CHAIRMAN, DELHI PUBLIC SCHOOL, BHIMAVARAM',
    image: SrinivasaRaju,
    text:
      'I am pleased to testify the selfless and yeomen services of Sri V.S.R.Avadhani, Advocate, Avadhani & Associates. The personalized legal services that he has offered for me is beyond my expectations and I have always had the experience of painless litigations. I have found him to be a very humble person who is ever ready to render his services to poor and down trodden. I wish him all success in all his endeavours.',
  },
  {
    name: 'L.Venkateshwar Rao',
    designation: 'Industrialist from Hyderabad, Client',
    image: VenkateshwarRao,
    text:
      "We have Shri Seetharama Avadhani Sir and Soubhagya Valli Ma'am assisting us for a couple of years in a strenuous case against our business tenants. Given the usual ambiguity with judicial Jargon and abnormal delays in hearings that cause mental havoc, we switched advocates frequently. We finally were relieved to entrust our case here. Shri Avadhani Sir and his team have not only been satisfyingly responsive but have also been Highly Reliable with Relevent Exceptional Experience. Highly recommended for your legal needs.",
  },
  {
    name: 'Krishna Reddy',
    designation: 'Client',
    image: KrishnaReddy,
    text:
      'I have found the right solution for my problem in this office and they treat clients very well anytime on phone also. After seeing the office and the way you received I got the confidence that my problem will be solved',
  },
  {
    name: 'R.Srinivasan',
    designation: 'Client',
    image: Srinivasan,
    text:
      'Avadhani & Associate helped us at every stage in our pursuit for justice, from hand holding in the initial stages through guiding us to build a stronger case in the midst of challenges and finally help us reach a closure. It wasnt easy considering we were a group of more than twenty diverse individuals connected only by a common cause. We were overwhelmed when your firm decided to accept our brief not as just another case, but as a cause that deserved to be helped and a case worth fighting for. Your patience, perseverance, passion, and personal attention from the Top are what made us travel this far successfully. It is not only justice that we got with your help, but life-saving outcome, for most of our group members were on the verge of financial ruin. You have delivered us hope, confidence has returned to our life!',
  },
  {
    name: 'Bandla Ganesh',
    designation: 'Indian Film Actor, Client',
    image: BandlaGanesh,
    text:
      'అద్భుతమైన ప్రేమ, అనురాగం, అప్యాయత మనది అనే నమ్మకం, మంచి బరోసా,ఎల్లవేళలా తన క్లయింట్ ను కాపాడుతారనే అందమైన ఆలోచనతో కూడిన అభిప్రాయం. మీరు ఎప్పుడూ ఇలాగే ఉండాలని ఇంకా అభివృద్ధి చెంది మంచి సమాజానికి, మంచితనానికి పునాధై ధర్మానికి,న్యాయానికి న్యాయం జరుగుతుందని నమ్మకంతో మీ దగ్గరకు వస్తున్న ప్రతి ఒక్కరికి ఆనందాన్ని కలుగజేయాలని కోరుకొంటూ.',
  },
  {
    name: 'Rajender Reddy B',
    designation: 'Florida USA, Client',
    image: RajenderReddy,
    text:
      'My experiences with Avadhani Associates and their team were stupendous through and through. These people know their craft and walk the extra mile for you. I consulted with Avadhani Asociates on various occasions and each one of them was handled with utmost care and professionalism. I also appreciated working with people that care for you on a personal level instead of just handling you as a “case“. Highly recommend',
  },
  {
    name: 'Prabhavathi',
    designation: 'Client',
    image: Prabhavathi,
    text:
      'Hi… Iam prabhavathi pothu from Hyderabad… I went to Avadhani and associates of 2 different cases … Avadhani sir explained everything how the case gonna be and the consequences of the case very honestly and guided me the right way.. sir takes care of his client…very impressive for I would highly recommend Avadhani sir to everyone…',
  },
  {
    name: 'Manohar Kumar',
    designation: 'Client',
    image: unknown,
    text:
      'I would highly recommend the legal team at Avadani Associates; this group is highly professional, dedicated individuals who have exemplary knowledge and understanding of Indian legal requirements and can guide the NRIs through Indian legal issues. They have helped resolve several property litigations and have become my trusted advisors during any significant deals. I am happy to have them as my legal representative and guide in India.',
  },
  {
    name: 'Kushal',
    designation: 'Client',
    image: unknown,
    text:
      'In times where people are looking at making money, sell their services without having any positive impact on the buyer. There are still few people who look at making their clients satisfied rather then minting money. when looking for an advocate for a case, we came across Advocate Vijay Bhasker, from (Avadhani and Associates) is down to earth I could say, he heard the case gave us the exact inputs and also the max risk involved, if any. Advocate Vijay Bhasker is one who does not sell his services, rather acts as a consultant giving the right advice and leaves on us to decide to go with him or any other advocate. I was fortune enough to go with him after his advice, the best part he does not try to drag on the case to mint money. what ever be the court decision, we are happy we were in the right and safe hands and not taken for ride. I would surely like to recommend Advocate to anyone who is looking for right solution, in fact I have referred few of my friends who needed some legal assistance.',
  },
  {
    name: 'Parupalli Gopichand',
    designation: 'Client',
    image: unknown,
    text:
      'Avadhani & Associates is a well experienced Firm and the discussion with Sr. advocate Vijay Bhaskar Reddy Garu was first interaction, where in which he had cleared all my doubts and made me confidant by explaining the end to End process of filing the case in different courts. I confidently say that The Avadhani & Associates had s one place where you can get the Trust and Confidant in terms of Legal Cases.',
  },
  {
    name: 'M Murali',
    designation: 'Client',
    image: unknown,
    text:
      "My experience with Avadhani associates by Vijay Bhaskar is his in depth knowledge, experience, and well-versed in his field or area of advocacy. He has ability to communicate complex ideas clearly and effectively, both in person and in written form. He contributes the effectiveness in representing or championing the cause, whether it's in legal matters or other areas of advocacy.",
  },
  {
    name: 'Satish Bhaskara',
    designation: 'Client',
    image: unknown,
    text:
      'I would heartfully say that Avadhani and Associates is the best of the best legal service providers I have come across. Indebted to them for helping me in my case in time. Before reaching out to Avadhani and Associates I had a very tough time. But Sir V.S.R Avadhani Garu, with his exceptional knowledgeable about the law and Judiciary System, provided me the needed legal guidance and I am supported throughout my tenure of Case by his team, Valli garu and others, have ensured I have been given the right Justice and most importantly in time. Avadhani garu and his team, Valli garu and others, are exceptionally kind and courteous and very Co-operative while they represented my case and guided and supported me exceptionally in the court room as well as personally. I strongly recommend them for any legal advices. Thank you Avadhani and Associates.',
  },
  {
    name: 'Sravan Kumar Poienti',
    designation: 'Client',
    image: unknown,
    text:
      "Avadhani and Associates is the best of the best legal service providers in Hyderabad. Hands down. V.S.R Avadhani sir is very knowledgeable about law and runs his office with utmost professionalism and integrity. Avadhani garu and his team (Soubhagya Valli ma'am and others) are exceptional with keeping us up-to-date with our case, represented us promptly and effectively in the court and guided us smoothly throughout the legal battle. I strongly recommend them for any legal advices. Thank you Avadhani and Associates.",
  },
  {
    name: 'OV Ramana',
    designation: 'Client',
    image: unknown,
    text:
      'Excellent, Quite friendly and understanding patiently the nature of the case and the person in particular being any petitioner will be under tremendous anxiety and pressure being tagged with multiple legal issues from financial institutions. As a whole, this is the perfect shelter for the needy.',
  },
  {
    name: 'Murali Krishna Kambampati',
    designation: 'Client',
    image: unknown,
    text:
      'Very well organized, highly professional service. Really admire their work ethics and well appreciate their excellent advise.',
  },
  {
    name: 'B. K. Reddy',
    designation: 'Client',
    image: unknown,
    text:
      'I am very happy to share my experience with Avadhani & Associates on this platform. The firm has resource full in knowledge of law, provides good court craft and deals the client honestly. The responsiveness and assistance provided by the firm’s team is highly appreciable. I have seen debating/discussing with all advocates in their office before presenting the case during arguments in the court, which I feel really amazing. The analysis of the case by Sri Avadhani, zeroing down the relief required, expression of confidence, providing crystal clear guidance to colleague advocates/partners is praiseworthy. The firm maintains punctuality, transparency and reasonableness.',
  },
  {
    name: 'Sridhar Prasangi',
    designation: 'Client',
    image: unknown,
    text:
      'Avadhani & Associates is a Fabulous Law Firm. They provide exceptional client service, have positive word-of-mouth and a team with expertise consistent with their firm’s mission. It’s very efficient and easy to work with them for any Legal matters.',
  },
  {
    name: 'Pravin Pandey',
    designation: 'Client',
    image: unknown,
    text:
      "Very profound in legal acumen. They have a good standing in the corporate legal issues and can really bring 'out of the box ideas' to address the situation at hand. They are quite pragmatic in deliberating client concerns. A very professional firm on which you can bank on. My sincere regards for Adv Avadhani and his associates.",
  },
  {
    name: 'Tulasi Prasad',
    designation: 'Client',
    image: unknown,
    text:
      'The best team available for any legal matter, will get genuine solutions. Headed by the most respectable person in the legal world, Sri Avadhani garu.',
  },
  {
    name: 'Hari Prasad',
    designation: 'Client',
    image: unknown,
    text:
      "Avadhani and Associates headed by Shri V.S.R. Avadhani Garu is a premium law firm in Telangana. Avadhani Garu and myself have an association of more than 10 years. I am utmost satisfied with the personal experience and care being provided for my cases. Professional and client convenient advice makes him unique and valuable. Avadhani garu's book publications on revenue, criminal and other legal fields are highly regarded in India and especially Telangana. Avadhani garu's lectures and counselling are used by law students, young professionals, and judges. His sincerity and honest service are inspirational to clients and the public at large. I highly recommend Avadhani and Associates to everybody.",
  },
  {
    name: 'S.Ananth',
    designation: 'Client',
    image: unknown,
    text:
      'In this modern day and age, it is extremely rare to come across someone who displays humanity. I could experience this in the presence of Seetharama Avadhani Sir and Soubhagya Valli Madam. With a great fortune by my side, I was able to connect with Sir and Madam for legal advice. Coming to know that I have travelled from Tamil Nadu, Sir and Madam were extremely gracious enough to take up my case and guide me at every step. The attention to detail on every point discussed was just impeccable. Words fall short to express my gratitude to Sir and Madam for helping me out with my case. To the good souls reading this review, there is no second thought needed in connecting with Sir, Madam and team for your legal needs and I wish you all success!',
  },
  {
    name: 'Asritha Mallisetty',
    designation: 'Client',
    image: unknown,
    text:
      'Avadhani and Associates is a great legal firm in India. They deserve highest level of appreciation for their incredible services and ability to work relentlessly o solutions in resolving critical legal challenges. The key to their success is the illustrious efforts they put in to give relief to the hundreds of clients across India. Avadhani sir and Soubhagya Valli ma’am put their combined efforts and marvellous advocacy and helped my family enormously in our very complicated case. We as a family are wholeheartedly very thankful to Sri Avadhani sir and Soubhagya Valli ma’am for their magnanimous hard work. In future days many more milestones will in their account.',
  },
  {
    name: 'Sudhakar Rao Gagadam',
    designation: 'Client',
    image: unknown,
    text:
      'Avadhani and Associates is one of the best law firms in this country. Avadhani sir is a prodigy in himself. An encyclopedia and highly talented senior most counsel of India. We feel very blessed to be associates with him and Soubhagya Valli ma’am. Thank you so much Avadhani and Associates for being a part of our lows and highs as a pillar of strength.',
  },
  {
    name: 'Gouse',
    designation: 'Client',
    image: unknown,
    text:
      'Excellent in both professionalism and experience. My experience was exceptionally great with the firm. Legal acumen of Sri VSR Avadhani garu is fantastic. The team led by him is very thorough and well organized. In entirety, I have had a positive experience.',
  },
  {
    name: 'K. R. Subba Reddy',
    designation: 'Client',
    image: unknown,
    text:
      'I along with my family Dr. K. Parvathi, K Sireesh and K Sumalatha have been dealing with Avadhani and Associates as victims of Sahithi Projects. The service from Avadhani garu, Ms. Soubhagya Valli and their all-team members is exceptionally very helpful and gave a lot of solace. We are grateful to them and wish great success and growth. Really the Country and law needs people like you. Thank you.',
  },
  {
    name: 'Anupama',
    designation: 'Client',
    image: unknown,
    text:
      'I been to Avadhani and Associates for a family issue case. Avadhani sir and his daughter Soubhagya Valli ma’am are best people I ever came across. Their dedication, support and advice from the whole team made me feel very strong and safe in my critical times. Out of 10 advocates whom I met were extremely commercial and trying to take advantage but Avadhani and Associates are very ethical people who made me feel like girl from their own family and made me feel valued. Thank you, sir and madam. Law needs more people like you.',
  },
  {
    name: 'Krishna Mohan Reddy',
    designation: 'Client',
    image: unknown,
    text:
      'I have been to Avadhani and Associates for Sahithi Infratec Ventures Pvt. Ltd., Financial Fraud cases. Avadhani garu, his daughter Soubhagya Valli ma’am and Vijaya Bhaskar Reddy garu and their team are the best people I ever came across. Their dedication, support and time to time advice from the whole team made me feel very strong and safe in our critical times. Nowadays, majority advocates whom we met were extremely commercial and trying to take advantage. Avadhani and associates are very ethical and most trustworthy people who made us feel very comfortable at a very critical time. Thank you, sir and madam. Country and law, needs more people like you.',
  },
  {
    name: 'VG Ranganath',
    designation: 'Client',
    image: unknown,
    text:
      'Being a Professor in Law, I have been associated with Avadhani Associates since 2018. Avadhani garu is down to earth. I called him to my University to interact with the law final year students on the aspects of "Evidence" and "Witness Examination". It was a very enlightening session to all the students and concerned subject teachers too. We had a collaboration for our National Conference event with Avadhani Associates as a knowledge partner. The support of Avadhani garu and Ms. Valli garu to the event is immeasurable. All other associates in the office had good expertise in the field of law.',
  },
  {
    name: 'Someshwar Rao Kodukula',
    designation: 'Client',
    image: unknown,
    text:
      'Avadhani and Associates is the best legal Service Providers, Highly Professional, Authentic and very reliable legal services. Great Advisors with a lot of experience. Avadhani sir was a very knowledgeable person, in the case perspective every point he will listen and Quite friendly, understanding patiently the nature of the case. Avadhani garu and his team Soubhagya Valli Madam, Bhaskar Reddy Garu and his team are exceptional with keeping us up-to-date with our case, represented us promptly and effectively in the court and guided us smoothly throughout the legal battle. I strongly recommend them for any legal advice. Thank you Avadhani and Associates.',
  },
  {
    name: 'Shyla Sree',
    designation: 'Client',
    image: unknown,
    text:
      'I am so privileged to have Avadhani & Associates as my support system, lawyer. The team has exceptional members with tremendous knowledge on the law and the way it handles every step is very nice. Avadhani Sir and Soubhagya Valli Mam, and team are the best. The efforts they put in are amazing and they supported me and my family at every step. I am so grateful and will be forever. 😊',
  },
  {
    name: 'Sai Sharan Veer Kothakonda',
    designation: 'Client',
    image: unknown,
    text:
      'We happened to sought the services of Avadhani and Associates for handling a legal matter and they have been doing an impeccable job! Since the initial interactions itself it was pretty clear that we have approached the experts in their fields. At every stage they were very practical and supportive and not only handled the case in an efficient matter but also stood by us at every need of the hour. Avadhani Sir is a highly reliable expert and has always provided the best legal guidance, very lucky for those aware of and accessible to his services. Valli Ma’am has always ensured great service and her kind & supportive nature has immensely helped us transition this tough path smoothly. If not for them, the journey would have been miserable and unimaginable for us. I’m extremely grateful to them for their exceptional services and really appreciate the way they are assisting and guiding the victims of the legal battle towards victory. I strongly recommend Avadhani and Associates for those who are looking for expert level legal counsel and kind & customer friendly service. They are the best you can get!',
  },
  {
    name: 'Sodekar Mannava',
    designation: 'Client',
    image: unknown,
    text:
      "I am glad that I could get an opportunity to approach Avadhani and Associates. I am an in-house counsel having 24 years of standing and having experience of briefing many Senior Counsels even at the Supreme Court level. But my experience with Avadhani and Associates outweighs all my previous experiences as the strategy Mr. VSR Avadhani frames in a given matter is altogether different. What matters in a contentious matter is the strategy. Their entire team is up to the mark and their timely services are highly commendable. I must say with all the emphasis that Mr. Avadhani's strategies are masterpieces, to compare with others. Moreover, the language they use in the drafts is marvelous. My overall experience with this firm is unmatchable and I very strongly recommend to approach Avadhani and Associates for whatever the legal work one has in Hyderabad.",
  },
  {
    name: 'Moksha Samhitha',
    designation: 'Client',
    image: unknown,
    text:
      'My internship at Avadhani and Associates was truly exceptional. The team was supportive, the work culture was fantastic, commitment to personal and professional growth made it an unforgettable experience and the opportunities for growth were abundant. I highly recommend Avadhani and Associates for an internship experience that goes above and beyond expectations. Thank you, Avadhani and Associates, for an unforgettable opportunity!',
  },
  {
    name: 'Madhu Babu',
    designation: 'Client',
    image: unknown,
    text:
      'Our company operates in seeds, infrastructure, and other allied businesses. We have filed a suit through Avadhani & Associates. Avadhani sir’s drafting and approach to handling the matter was outstanding. His way of presenting the case and convincing the bench is exceptional. He has in-depth knowledge in all areas. We are delighted with the services provided by Avadhani & Associates. It was a pleasure to meet Mr. Avadhani sir.',
  },
  {
    name: 'Bhargav Gandhi',
    designation: 'Client',
    image: unknown,
    text:
      'The Sr. Advocates took the time to mentor me, offering insights into complex legal issues and involving me in meaningful projects. Their commitment to client success is evident in every aspect of their work. If you are looking for a law firm that’s both professional and friendly, I totally recommend Avadhani Legal.',
  },
  {
    name: 'Laasya Swaraj',
    designation: 'Client',
    image: unknown,
    text:
      'I am in my penultimate year of law school. During my time as an intern at Avadhani & Associates, I had the opportunity to work closely to the Avadhani Sir, who is a former Secretary General of Hon’ble Supreme Court and other experienced advocates and gain practical knowledge and worked on live cases. Working in a professional legal environment also taught me about teamwork and collaboration. I had the opportunity to interact with junior lawyers, support staff, and other interns, which enhanced my ability to work effectively as part of a team. My legal internship was a valuable experience that not only complemented my academic studies but also helped me gain practical skills and insights that will be beneficial as I pursue a career in law. I am grateful for the opportunity to have learned from seasoned professionals and contribute to meaningful legal work during my internship. I highly recommend this place for the people who are in legal need as their work and integrity in all the interactions with clients sets a strong example in the legal community. Their Legal office demonstrates impressive expertise in navigating complex legal issues.',
  },
  {
    name: 'Roshan S. Chowdary',
    designation: 'Client',
    image: unknown,
    text:
      'I am currently interning at Avadhani and Associates, and it has been such a great experience! The team here is not only super professional and knowledgeable, but also friendly and supportive. If you are looking for legal help, I highly recommend Avadhani and Associates. They really care about their clients and go above and beyond.',
  },
];

const TestimonialsOne = () => {
  const params = {
    slidesPerView: 1,
    spaceBetween: 30,
    loop: false,
    speed: 1000,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    breakpoints: {
      640: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
    },
  };

  return (
    <div className='testimonials-container'>
      <Swiper {...params}>
        {testimonials.map((testimonial, index) => (
          <div className='testimonial-box' key={index}>
            <div className='testimonial-image'>
              <img src={testimonial.image} alt={testimonial.name} />
            </div>
            <div className='testimonial-content'>
              <h4 className='testimonial-name'>{testimonial.name}</h4>
              <span className='testimonial-designation'>
                {testimonial.designation}
              </span>
              <p className='testimonial-text'>{testimonial.text}</p>
            </div>
          </div>
        ))}
      </Swiper>
    </div>
  );
};

export default TestimonialsOne;
